import React from 'react'
import styled from '@emotion/styled'
import LogoWhiteSvg from '../../../static/images/logo_white.svg'
import LocationAddress from '../../../static/images/location_address.svg'
import LocationPhone from '../../../static/images/location_phone.svg'
import LocationEmail from '../../../static/images/location_email.svg'
import IconExternal from '../../../static/images/external_link.svg'

// Location
const LocationContainer = styled.div` 
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	background: rgba(61,64,68,0.85);
	padding: 3.5rem 0;
`
const LocationLogoContainer = styled.div`
  padding-bottom: 2rem;
`
const LocationItemContainer = styled.div` 
	display: flex; 
	flex-direction: row;
	min-width: 22rem;
	justify-content: center;
	padding: 0.5rem;
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		min-width: 18rem;
	}
`
const LocationIconContainer = styled.div`
	display: flex;
	flex: 1;
	max-width: 3.5rem;
	justify-content: center;
`
const LocationDetailText = styled.div`
	display: inline;
	flex: 4;
	width: 22rem;
	font-size: 1.2em;
	font-family: "Apple SD Gothic Neo";
	line-height: 1.5rem;
	color: ${props => props.theme.colors.background};
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		width: 16rem;
		font-size: 1em;
	}
`
const TagLink = styled.a`
	font-family: "Apple SD Gothic Neo";
	color: ${props => props.fontColor ? props.theme.colors[props.fontColor] : props.theme.colors.text};
	text-decoration: none;
`

const AboutUsLocation = () => {
  return (
    <LocationContainer>
			<LocationLogoContainer>
				<LogoWhiteSvg />
			</LocationLogoContainer>
			<LocationItemContainer>
				<LocationIconContainer>
					<LocationAddress />
				</LocationIconContainer>
				<LocationDetailText>
						<TagLink fontColor={`background`} href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">
							{`New Life Church (Dublin Campus)`}<br/>
							{`6444 Sierra Ct, Dublin,`}{` CA 94568`}
							<IconExternal style={{ marginLeft: '0.5rem', marginBottom: '1px' }} />
						</TagLink>
				</LocationDetailText>
			</LocationItemContainer>
			<LocationItemContainer>
				<LocationIconContainer>
					<LocationAddress />
				</LocationIconContainer>
				<LocationDetailText>
					{`Mailing Address:`}<br/>
					
					{`5678 Bellevue Cir. Dublin,`}{` CA 94568`}
				</LocationDetailText>
			</LocationItemContainer>
			<LocationItemContainer>
				<LocationIconContainer>
					<LocationPhone />
				</LocationIconContainer>
				<LocationDetailText>
					{`(510) 582-1240`}
				</LocationDetailText>
			</LocationItemContainer>
			<LocationItemContainer>
				<LocationIconContainer>
					<LocationEmail />
				</LocationIconContainer>
				<LocationDetailText>
					{`info@joyfulchurch.us`}
				</LocationDetailText>
			</LocationItemContainer>
    </LocationContainer>
  )
}

export default AboutUsLocation

import React from 'react'
import Layout from '../../components/Layout'
import ContainerFullWidth from '../../components/ContainerFullWidth'
import PageTitle from '../../components/PageTitle'
import BodyPlaceholder from '../../components/BodyPlaceholder'
import SEO from '../../components/SEO'
import AboutUsPage from '../../components/Info/AboutUsPage'

const AboutUs = ({ data }) => {
  return (
    <Layout>
      <SEO title="교회 소개 | 안내 - 죠이플 교회" description="About Joyful Church" />
      
      <ContainerFullWidth background={'background'}>
        <AboutUsPage />
      </ContainerFullWidth>
    </Layout>
  )
}

export default AboutUs
